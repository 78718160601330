import { useEffect } from 'react';
import { Box, Card, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Header from '../../components/Header/Header';

const useStyles = makeStyles()(() => ({
    mainContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Merriweather',
        width: '100%',

        '& a:link, a:visited, a:focus, a:hover, a:active': {
            color: '#1976d2',
            textDecoration: 'none'
        }
    },

    mainPaper: {
        marginBottom: '20px',
        marginTop: '16px',
        padding: '30px',
        width: '50%',

        '@media (max-width: 1000px)': {
            padding: '20px',
            width: '80%'
        }
    },

    defaultCard: {
        display: 'flex',
        gap: '24px',
        marginBottom: '30px',
        padding: '20px',

        '&:last-child': {
            marginBottom: 0
        },

        '& p, & h5': {
            marginBottom: '16px',

        },

        '& p:last-child': {
            marginBottom: 0
        },

        '@media (max-width: 767px)': {
            '& img': {
                display: 'none'
            }
        }
    },

    header: {
        marginBottom: '16px'
    },

    bold: {
        fontWeight: 'bold'
    }
}));

const Portfolio = () => {
    const { classes, cx } = useStyles();

    useEffect(() => {
        document.title = 'Chris Tybur - Portfolio';
    });

    return (
        <Box>
            <Header />

            <Box className={cx(classes.mainContainer)}>
                <Paper className={cx(classes.mainPaper)} elevation={4}>
                    <Typography variant='body2' className={cx(classes.header)}>
                        I've set up a way to demonstrate the various web apps I've created over the years, to see them in action rather than just look at
                        the code. Instructions can be found below, and the code itself can be found at <a href='https://github.com/thisiscmt'>GitHub</a>. Enjoy.
                    </Typography>

                    <Card elevation={2} className={cx(classes.defaultCard)}>
                        <img src='/images/bmb_thumbnail.jpg' alt='Bookmark Browser thumbnail' />

                        <Box>
                            <Typography variant='h5'>Bookmark Browser</Typography>

                            <Typography variant='body2'>
                                Browse to <a href='https://bmb.cmtybur.com'>the site</a> and click Config in the footer to get to the configuration
                                page. Type in the credentials below and hit Refresh, then click Bookmarks in the footer. A bunch of sample bookmarks
                                have been included. The site was designed for mobile devices but works fine on desktop browsers.
                            </Typography>

                            <Typography variant='body2'>
                                <span className={cx(classes.bold)}>User name</span>: demo@example.com<br/>
                                <span className={cx(classes.bold)}>Password</span>: Pass.Word1
                            </Typography>
                        </Box>
                    </Card>

                    <Card elevation={2} className={cx(classes.defaultCard)}>
                        <img src='/images/ck_thumbnail.jpg' alt='Cocktail Keeper thumbnail'/>

                        <Box>
                            <Typography variant='h5'>Cocktail Keeper</Typography>

                            <Typography variant='body2'>
                                Browse to <a href='https://ck.cmtybur.com'>the site</a> and click Config in the footer to get to the configuration
                                page. Type in the credentials below and hit Refresh, then click Cocktail Keeper in the header to return to the home
                                page. Several well-known cocktail recipes have been included. The site was designed for mobile devices but works fine
                                on desktop browsers.
                            </Typography>

                            <Typography variant='body2'>
                                <span className={cx(classes.bold)}>User name</span>: demo@example.com<br/>
                                <span className={cx(classes.bold)}>Password</span>: Pass.Word1
                            </Typography>
                        </Box>
                    </Card>

                    <Card elevation={2} className={cx(classes.defaultCard)}>
                        <img src='/images/diht_thumbnail.jpg' alt='Did I Hike That? thumbnail'/>

                        <Box>
                            <Typography variant='h5'>Did I Hike That?</Typography>

                            <Typography variant='body2'>
                                Browse to <a href='https://diht.cmtybur.com'>the site</a> and click Login in the header. Type in the credentials below
                                and you will be taken to the home page. There are some sample hikes to view/edit or you can create your own.
                            </Typography>

                            <Typography variant='body2'>
                                <span className={cx(classes.bold)}>User name</span>: demo@example.com<br/>
                                <span className={cx(classes.bold)}>Password</span>: Pass.Word1
                            </Typography>
                        </Box>
                    </Card>
                </Paper>
            </Box>
        </Box>
    )
}

export default Portfolio;
